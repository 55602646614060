import { sliders } from "./swiper.js";

const headerOffset = () => {
  const header = document.querySelector('header.site-header');
  document.documentElement.style.setProperty('--header-offset', `${header.offsetHeight}px`);
};

const toggleNav = (event) => {
  event.preventDefault();
  document.body.classList.toggle('js-nav');
}

const toggleSubnav = (event) => {
  const submenu = event.target.nextElementSibling

  if(submenu.classList.contains('js-active')) {
    return true;
  }
  submenu.classList.add('js-active');
  event.preventDefault();
};

const nav = () => {
  const toggles = document.querySelectorAll('.menu-toggle');
  for(let i = 0; i < toggles.length; i++) {
    toggles[i].addEventListener('click', toggleNav);
  }

  const submenuToggles = document.querySelectorAll('.menu-item-has-children a');
  for(let i = 0; i < submenuToggles.length; i++) {
    submenuToggles[i].addEventListener('click', toggleSubnav);
  }
}

document.addEventListener('DOMContentLoaded', event => {
  headerOffset();
  sliders();
  nav();

  var d = document.querySelector('.course-completion-rate');

  if(d) {
    d.parentNode.appendChild(d);
  }

  var d = document.querySelector('.ld-course-status');

  if(d) {
    d.parentNode.appendChild(d);
  }

  var d = document.querySelector('.meter');

  if(d) {
    d.parentNode.appendChild(d);
  }

  console.log('movied;');
});
