import Swiper, { Navigation, Pagination, Lazy } from 'swiper';

Swiper.use([Navigation, Pagination, Lazy]);

const sliders = function() {
  let swipers = document.querySelectorAll('.swiper');
  if (swipers.length) {
    swipers.forEach((item, index) => {
      const slider = item.querySelector('.swiper-container');
      const counter = item.querySelector('.swiper-counter');
      const current = item.querySelector('.current');
      if (counter) counter.classList.add(`counter-${index}`);
      let opts = slider.dataset.opts ? JSON.parse(slider.dataset.opts) : '';

      if (!opts) {
        opts = {
          observe: true,
          loop: true,
          spaceBetween: 40,
          slidesPerView: 1,
          // centeredSlides: true,
          // watchOverflow: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          lazy: {
            checkInView: true,
            loadPrevNext: true,
          },
          breakpoints: {
            768: {
              slidesPerView: 3
            }
          },
          navigation: {
            nextEl: item.querySelector('.swiper-controls .swiper-next'),
            prevEl: item.querySelector('.swiper-controls .swiper-prev'),
          },
          on: {
            init() {
              window.dispatchEvent(new Event('resize'));
            },
            slideChange(swiper) {
              if (current) current.innerText = `${swiper.realIndex + 1}`;
            }
          }
        };
      }

      const mySwiper = new Swiper(slider, opts);
    });
  }
};

export { sliders };
